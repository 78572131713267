/* Header */
header {
  background-color: $main-color;
  height: $header-height;
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  color: #fff;
  padding: 0px 20px;
  z-index: 2000;

  .logo {
    height: 100%;
    display: inline-block;
    vertical-align: top;
    font-weight: bold;
    color: #fff;
    font-size: 24px;

    span {
      margin-top: 7px;
      display: inline-block;
    }

    img {
      display: inline-block;
      padding: 12px 0px;
      margin-right: 10px;
      box-sizing: content-box;
      height: 26px;
      margin-top: -5px;
    }
  }

  .header-title {
    display: inline-block;
    height: 100%;
    line-height: 26px;
    padding: 12px 0px 12px 17px;
    box-sizing: border-box;
    vertical-align: top;
    position: relative;
    margin-left: 10px;
    font-weight: bold;

    &:before {
      width: 2px;
      height: 26px;
      content: '';
      display: block;
      position: absolute;
      left: 0px;
      top: 12px;
      background: #fff;
    }
  }

  .nav {
    float: right;
    height: 100%;
    padding: 0px;
    margin: 0px;

    li {
      height: 100%;
      margin-right: 20px;
      display: inline-block;
      margin-right: 30px;

      &:last-of-type {
        margin-right: 0px;
      }

      &:last-of-type {
        margin-right: 0px;
      }

      a {
        display: inline-table;
        height: 100%;
        color: inherit;

        i, span {
          display: table-cell;
          vertical-align: middle;
        }

        i {
          padding-right: 5px;
          font-size: 22px;
        }
      }

      .submenu {
        color: $text-color;
        min-width: 150px;

        li a {
          width: 100%;

          i {
            font-size: inherit;
            padding-right: 0px;
          }
        }
      }
    }
  }
}

/* General page content contaier */
.page-content {
  padding-top: $header-height;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1000;
}

/* Side nav */
nav.sidenav {
  width: $sidenav-width;
  position: fixed;
  background: $light-gray;
  top: 0px;
  left: 0px;
  height: 100%;
  //padding-top: 50px;
  box-sizing: border-box;
  color: #4f4f4f;
  z-index: 1000;
  overflow:hidden;
  font-size:14px;

  .hidden-scrollbar{
    position:absolute;
    top: 46px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    overflow:auto;

    ul {
      margin: 0px;
      padding: 0px;
      width: 100%;
      list-style: none;
      display:flex;
      flex-direction: column;

      li {
        margin: auto;
        width: 100%;

        a {
          height: 100%;
          width: 100%;
          box-sizing: border-box;
          padding: 20px 0px;
          text-align: center;
          display: block;
          color: inherit;

          div {
            width:100%;
            padding-right: 10%;
            padding-left: 5%;
          }

          &:hover, &.active {
            background: #fff;
            text-decoration: none;
          }

          &.active{
            color: $main-color;
          }

          &:before {
            content: '';
            display: block;
            width: 46px;
            height: 26px;
            margin: 0px auto 7px auto;
            background-repeat: no-repeat;
            background-size: 25px auto;
            background-position: center;
          }
        }

        &.transactions a:not(.active):before {
          background-image: url('../images/icons/transactions.svg');
        }
        &.transactions a.active:before {
          background-image: url('../images/icons/transactions-blue.svg');
        }
        &.orders a:not(.active):before {
          background-image: url('../images/icons/order.svg');
        }
        &.orders a.active:before {
          background-image: url('../images/icons/order-blue.svg');
        }
        &.setup-deviations a:not(.active):before {
          background-image: url('../images/icons/setup-deviations.svg');
        }
        &.setup-deviations a.active:before {
          background-image: url('../images/icons/setup-deviations-blue.svg');
        }
        &.deviations a:not(.active):before {
          background-image: url('../images/icons/deviations.svg');
        }
        &.deviations a.active:before {
          background-image: url('../images/icons/deviations-blue.svg');
        }
        &.settlements a:not(.active):before {
          background-image: url('../images/icons/settlements.svg');
        }
        &.settlements a.active:before {
          background-image: url('../images/icons/settlements-blue.svg');
        }
        &.settlementreports a:not(.active):before {
          background-image: url('../images/icons/settlementreports.svg');
          background-size: 20px auto;
        }
        &.settlementreports a.active:before {
          background-image: url('../images/icons/settlementreports-blue.svg');
          background-size: 20px auto;
        }
        &.payouts a:not(.active):before {
          background-image: url('../images/icons/settlementreports.svg');
          background-size: 20px auto;
        }
        &.payouts a.active:before {
          background-image: url('../images/icons/settlementreports-blue.svg');
          background-size: 20px auto;
        }
        &.customers a:not(.active):before {
          background-image: url('../images/icons/customers.svg');
          background-size: 20px auto;
        }
        &.customers a.active:before {
          background-image: url('../images/icons/customers-blue.svg');
          background-size: 20px auto;
        }
      }
    }
  }
}

/* Pagination */
ul.pagination {
  margin-top: 30px;
  & li.current {
    background-color: $main-color;
  }
}

/* Left bar */
.left-bar {
  height: calc(100% - 50px);
  background: #fff;
  padding: 30px;
  width: 300px;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  position: fixed;
  overflow-y: auto;
  z-index: 1000;

  fieldset {
    margin-bottom: 30px;
  }
}

.left-bar section {
  min-width: auto;
}

/* Key numbers */
.key-numbers {
  padding: 0px;
  margin: 0px;
  width: 100%;
  font-size: 0px;

  li {
    display: inline-block;
    color: $main-color;
    font-size: 35px;
    text-align: center;
    font-weight: bold;
    box-sizing: border-box;
    padding: 0px 20px;

    span {
      color: $dark-gray;
      font-size: $regular-font-size;
      display: block;
      font-weight: normal;
    }
  }

  /* one item */
  li:first-child:nth-last-child(1) {
    width: 100%;
  }
  /* two items */
  li:first-child:nth-last-child(2), li:first-child:nth-last-child(2) ~ li {
    width: 50%;
  }
  /* three items */
  li:first-child:nth-last-child(3), li:first-child:nth-last-child(3) ~ li {
    width: 33.3333%;
  }
  /* four items */
  li:first-child:nth-last-child(4), li:first-child:nth-last-child(4) ~ li {
    width: 25%;
  }
  /* Five items */
  li:first-child:nth-last-child(5), li:first-child:nth-last-child(5) ~ li {
    width: 20%;
  }

}

.separator {
  hr {
    display: block;
    width: 200px;
    margin: 20px auto;
    &:last-of-type {
      display: none;
    }
  }
}

input[type="radio"] {
  display: none;

  & + span {
    display: inline-block;
    width: 19px;
    height: 19px;
    margin: -1px 4px 0 0;
    vertical-align: middle;
    cursor: pointer;
    background: url("../images/icons/radio.png") -38px top no-repeat;
  }

  &:checked + span {
    background: url("../images/icons/radio.png") -57px top no-repeat;
  }
}

/* Charts */
#payment-chart {
  height: 200px;
}

/* User table */
.user-table {

  .actions {
    width: 80px;

    button {
      margin-right: 10px;

      &:last-of-type {
        margin-right: 0px;
      }

      i:before {
        vertical-align: middle;
      }
    }

  }

  .btn-delete {
    color: $main-color;
  }

}

// Feedback alert
.feedback-alert {
  position: absolute;
  background: #fff;
  width: 300px;
  box-sizing: border-box;
  right: 10px;
  top: 0px;
  z-index: 1000;
  opacity: 0;
  transition: opacity 500ms, top 500ms;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.16);

  &.open {
    opacity: 1;
    top: 10px;
  }

}

body > .feedback-alert {
  top: 50px;

  &.open {
    top: 60px;
  }
}

// Forms

form {
  dl > dd > input,select {
    margin-bottom: 0 !important;
  }

  dl > dd.info {
    font-size: 0.8em;
    margin-top: 4px;
  }

  dl.error > dd.info {
    display: none;
  }

  dl.error > dd.error {
    font-size: 0.8em;
    margin-top: 4px;
  }
}

.error-notice {
  margin: 20px 0;
  color: white;
  background: #dc3545;
  border: 1px solid #dc3545;
  border-radius: 2px;
  padding: 10px;
}

#general-loader {
  display: none;
  position: absolute;
  top: $header-height;
  right: 0px;
  width: 0;
  height: 0;
  border-top: 70px solid rgba(50, 50, 50, 0.7);
  border-left: 70px solid transparent;

  &:before {
    position: relative;
    content: '';
    display: block;
    width: 30px;
    height: 30px;
    background: url('../images/icons/ring-loader-blue.svg');
    background-repeat: no-repeat;
    background-size: 30px 30px;
    margin: -65px 0px 0px -35px;
  }
}

// Bootstrap tags input
div.tagsinput {
  & span.tag {
    border: 1px solid darken($main-color, 10);
    background: $main-color;
    color: #fff;
    & a {
      color: $light-gray;
    }
  }
}

.accordion-title {
  display: none;
}

.accordion-content {
  padding: 0;
  border: 0px solid #e6e6e6;
  border-bottom: 0;
  background-color: #fefefe;
  color: #222;
}

:last-child > .accordion-content:last-child {
  border-bottom: 0px solid #e6e6e6;
}

.accordion-item {
  padding: 0;
}

table {
  margin: 0;
}

.white-row {
  background-color: #fff !important;
}

.grey-row {
  background-color: #f1f1f1 !important;;
}

.post-report {
  color: #222;
  text-decoration: underline;
  &:hover {
    color: lighten(#222, 30);
  }
}

.posted-report {
  color: #222;
  cursor: default;
  &:hover {
    color: #222;
  }
}

.unmatched {
  @extend .post-report;
}

.matched {
  @extend .posted-report;
}

.swal2-modal .swal2-styled + .swal2-styled {
  margin-top: 0;
}

.accordion-icon-down {
  background: url('../images/icons/accordion-arrow-down.png') center no-repeat;
}

.accordion-opener {
  height: 24px;
  display: block;
}

.accordion-icon-up {
  background: url('../images/icons/accordion-arrow-up.png') center no-repeat;
}

.report-detail {
  width: 50%;
  &.info {
    float: left;
  }
  &.actions {
    padding: 0 10%;
    margin-top: 69px;
    float: right;
    text-align: center;
    > .button {
      font-size: 20px;
    }
  }
}

.report-details {
  th {
    text-align: left;
  }
  td {
    text-align: right;
  }
  caption {
    text-align: left;
    padding-left: 50px;
    font-size: 34px;
  }
}

.nowrap {
  white-space: nowrap;
}

.swal-select-override {
  padding: .5rem !important;
  padding-right: 1.5rem !important;
}

ul.file_list {
  list-style-type: none;
  margin: 0;

  li {
    display: flex;
    align-items:center;
    margin: 2px 0 2px 0;

    img.icon {
      width: 20px;
      height: 20px;
      margin-left: auto;
    }
  }


}

