@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 700;
  src: url("../fonts/Roboto/Roboto-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  src: url("../fonts/Roboto/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 300;
  src: url("../fonts/Roboto/Roboto-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 200;
  src: url("../fonts/Roboto/Roboto-Thin.ttf") format("truetype");
}

body {
  font-family: 'Roboto', sans-serif;
  padding: 20px;

  &.page-container {
    background: $light-gray;
    padding: 50px 0px 0px $sidenav-width;
    color: $text-color;
    overflow-x: hidden;
  }
}

h4 {
  font-weight: 600;
  color: $main-color;
}

.button {
  background: $main-color;
}

tr.clickable-row {
  cursor: pointer;
  &:hover {
    background: #c7d2de;
  }
}

/* Foundation override */
.reveal:focus {
  outline: none;
}