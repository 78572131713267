// Colors
$main-color: #2a63c7;
$dark-gray: #828282;
$gray: #c4c4c4;
$light-gray: #f1f1f1;
$yellow: #F2AD4A;
$text-color: #222;

// Sizes
$sidenav-width: 120px;
$header-height: 50px;
$regular-font-size: 15px;