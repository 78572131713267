.content {
  padding: 30px;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  max-width: 1024px;
  min-height: 100%;
  background: #fff;
  overflow-x: auto;

  &.pushed {
    max-width: 100%;
    background: transparent;
    padding-left: 330px;
  }
}

section.white {
  background: #fff;
  width: 100%;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding: 10px;
  box-shadow: 0 1px 2px #ccc;
  //min-width: 600px;
  overflow-x: auto;

  .title {
    margin-bottom: 15px;
    color: $dark-gray;
  }
}

section.gray {
  @extend section.white;
  background: #eee;
  box-shadow: 0 1px 2px #aaa;
}

a.header-link {
  padding: 0px 5px;
}

.box-list {

  input {
    margin-bottom: 6px;
  }

  label {
    display: block;
  }

  &.horizontal label {
    display: inline-block;
  }

}

fieldset.search {
  position: relative;

  input {
    margin-bottom: 0px;
    padding-right: 40px;
  }

  button {
    background: none;
    position: absolute;
    right: 0px;
    top: 0px;
    height: 40px;
    width: 40px;

    &:before {
      content: '';
      background-image: url('../images/icons/search.png');
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      display: block;
      background-size: 20px 20px;
      left: 10px;
      top: 10px;
      position: absolute;
    }
  }
}

fieldset.datepickers {
  input {
    font-size: 15px;
    width: 105px;
    display: inline-block;
  }

  .arrow {
    width: 15px;
    margin: 0px 3px;
  }
}

/* Table */
table {
  input[type='text'], select, textarea, button, .button {
    margin-bottom: 0px;
  }
}

/* Status icons */
.status-icon {
  &.green {
    color: #219653;
  }
  &.red {
    color: #EB5757;
  }
}

/* Buttons */
.button {
  &:focus {
    outline: none;
  }

  &.hollow{
    background: #fff;
    border: 2px solid #2a63c7;
    color: #2a63c7;
  }

  &.light{
    background: #eaeaea;
    color: #333;
  }

  &.loading {
    position: relative;
    padding-right: 40px;
    transition: padding 200ms;

    &:after {
      width: 20px;
      height: 20px;
      display: block;
      position: absolute;
      top: 9px;
      right: 10px;
      content: '';
      background-size: 100%;
      background-image: url('../images/icons/ring-loader-blue.svg');
    }
  }

  .fa{
    margin-left: 10px;
  }
}

.button-group.spaced .button {
  margin-right: 10px;
}

.error {
  color: red;
}

.page-divider {
  display: block;
  height: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 1px solid $gray;
  border-top: 0;
  margin: $hr-margin;
  clear: both;
  box-sizing: border-box;
}

.left-gutter {
  padding-left: 10px;
}

.right-gutter {
  padding-right: 10px;
}

.tabs-content .global-panel {
  padding: $tab-content-padding;
}